var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * device-uuid.js v1.0.4 (https://github.com/biggora/device-uuid/)
 * Copyright 2016-2017 Alexey Gordeyev
 * Licensed under MIT (https://github.com/biggora/device-uuid/blob/master/LICENSE)
 */

/*global
 screen, window, navigator
 */
(function (exports) {
  'use strict';

  var BOTS = ["\\+https:\\/\\/developers.google.com\\/\\+\\/web\\/snippet\\/", "googlebot", "baiduspider", "gurujibot", "yandexbot", "slurp", "msnbot", "bingbot", "facebookexternalhit", "linkedinbot", "twitterbot", "slackbot", "telegrambot", "applebot", "pingdom", "tumblr ", "Embedly", "spbot"];
  var IS_BOT_REGEXP = new RegExp("^.*(" + BOTS.join("|") + ").*$");

  var DeviceUUID = function (options) {
    options = options ? options : {};
    var defOptions = {
      version: false,
      language: false,
      platform: true,
      os: true,
      pixelDepth: true,
      colorDepth: true,
      resolution: false,
      isAuthoritative: true,
      silkAccelerated: true,
      isKindleFire: true,
      isDesktop: true,
      isMobile: true,
      isTablet: true,
      isWindows: true,
      isLinux: true,
      isLinux64: true,
      isChromeOS: true,
      isMac: true,
      isiPad: true,
      isiPhone: true,
      isiPod: true,
      isAndroid: true,
      isSamsung: true,
      isSmartTV: true,
      isRaspberry: true,
      isBlackberry: true,
      isTouchScreen: true,
      isOpera: false,
      isIE: false,
      isEdge: false,
      isIECompatibilityMode: false,
      isSafari: false,
      isFirefox: false,
      isWebkit: false,
      isChrome: false,
      isKonqueror: false,
      isOmniWeb: false,
      isSeaMonkey: false,
      isFlock: false,
      isAmaya: false,
      isPhantomJS: false,
      isEpiphany: false,
      source: false,
      cpuCores: false
    };

    for (var key in options) {
      if (options.hasOwnProperty(key) && typeof defOptions[key] !== "undefined") {
        defOptions[key] = options[key];
      }
    }

    (this || _global).options = defOptions;
    (this || _global).version = "1.0.0";
    (this || _global)._Versions = {
      Edge: /Edge\/([\d\w\.\-]+)/i,
      Firefox: /firefox\/([\d\w\.\-]+)/i,
      IE: /msie\s([\d\.]+[\d])|trident\/\d+\.\d+;.*[rv:]+(\d+\.\d)/i,
      Chrome: /chrome\/([\d\w\.\-]+)/i,
      Chromium: /(?:chromium|crios)\/([\d\w\.\-]+)/i,
      Safari: /version\/([\d\w\.\-]+)/i,
      Opera: /version\/([\d\w\.\-]+)|OPR\/([\d\w\.\-]+)/i,
      Ps3: /([\d\w\.\-]+)\)\s*$/i,
      Psp: /([\d\w\.\-]+)\)?\s*$/i,
      Amaya: /amaya\/([\d\w\.\-]+)/i,
      SeaMonkey: /seamonkey\/([\d\w\.\-]+)/i,
      OmniWeb: /omniweb\/v([\d\w\.\-]+)/i,
      Flock: /flock\/([\d\w\.\-]+)/i,
      Epiphany: /epiphany\/([\d\w\.\-]+)/i,
      WinJs: /msapphost\/([\d\w\.\-]+)/i,
      PhantomJS: /phantomjs\/([\d\w\.\-]+)/i,
      UC: /UCBrowser\/([\d\w\.]+)/i
    };
    (this || _global)._Browsers = {
      Edge: /edge/i,
      Amaya: /amaya/i,
      Konqueror: /konqueror/i,
      Epiphany: /epiphany/i,
      SeaMonkey: /seamonkey/i,
      Flock: /flock/i,
      OmniWeb: /omniweb/i,
      Chromium: /chromium|crios/i,
      Chrome: /chrome/i,
      Safari: /safari/i,
      IE: /msie|trident/i,
      Opera: /opera|OPR/i,
      PS3: /playstation 3/i,
      PSP: /playstation portable/i,
      Firefox: /firefox/i,
      WinJs: /msapphost/i,
      PhantomJS: /phantomjs/i,
      UC: /UCBrowser/i
    };
    (this || _global)._OS = {
      Windows10: /windows nt 10\.0/i,
      Windows81: /windows nt 6\.3/i,
      Windows8: /windows nt 6\.2/i,
      Windows7: /windows nt 6\.1/i,
      UnknownWindows: /windows nt 6\.\d+/i,
      WindowsVista: /windows nt 6\.0/i,
      Windows2003: /windows nt 5\.2/i,
      WindowsXP: /windows nt 5\.1/i,
      Windows2000: /windows nt 5\.0/i,
      WindowsPhone8: /windows phone 8\./,
      OSXCheetah: /os x 10[._]0/i,
      OSXPuma: /os x 10[._]1(\D|$)/i,
      OSXJaguar: /os x 10[._]2/i,
      OSXPanther: /os x 10[._]3/i,
      OSXTiger: /os x 10[._]4/i,
      OSXLeopard: /os x 10[._]5/i,
      OSXSnowLeopard: /os x 10[._]6/i,
      OSXLion: /os x 10[._]7/i,
      OSXMountainLion: /os x 10[._]8/i,
      OSXMavericks: /os x 10[._]9/i,
      OSXYosemite: /os x 10[._]10/i,
      OSXElCapitan: /os x 10[._]11/i,
      OSXSierra: /os x 10[._]12/i,
      Mac: /os x/i,
      Linux: /linux/i,
      Linux64: /linux x86_64/i,
      ChromeOS: /cros/i,
      Wii: /wii/i,
      PS3: /playstation 3/i,
      PSP: /playstation portable/i,
      iPad: /\(iPad.*os (\d+)[._](\d+)/i,
      iPhone: /\(iPhone.*os (\d+)[._](\d+)/i,
      Bada: /Bada\/(\d+)\.(\d+)/i,
      Curl: /curl\/(\d+)\.(\d+)\.(\d+)/i
    };
    (this || _global)._Platform = {
      Windows: /windows nt/i,
      WindowsPhone: /windows phone/i,
      Mac: /macintosh/i,
      Linux: /linux/i,
      Wii: /wii/i,
      Playstation: /playstation/i,
      iPad: /ipad/i,
      iPod: /ipod/i,
      iPhone: /iphone/i,
      Android: /android/i,
      Blackberry: /blackberry/i,
      Samsung: /samsung/i,
      Curl: /curl/i
    };
    (this || _global).DefaultAgent = {
      isAuthoritative: true,
      isMobile: false,
      isTablet: false,
      isiPad: false,
      isiPod: false,
      isiPhone: false,
      isAndroid: false,
      isBlackberry: false,
      isOpera: false,
      isIE: false,
      isEdge: false,
      isIECompatibilityMode: false,
      isSafari: false,
      isFirefox: false,
      isWebkit: false,
      isChrome: false,
      isKonqueror: false,
      isOmniWeb: false,
      isSeaMonkey: false,
      isFlock: false,
      isAmaya: false,
      isPhantomJS: false,
      isEpiphany: false,
      isDesktop: false,
      isWindows: false,
      isLinux: false,
      isLinux64: false,
      isMac: false,
      isChromeOS: false,
      isBada: false,
      isSamsung: false,
      isRaspberry: false,
      isBot: false,
      isCurl: false,
      isAndroidTablet: false,
      isWinJs: false,
      isKindleFire: false,
      isSilk: false,
      isCaptive: false,
      isSmartTV: false,
      isUC: false,
      isTouchScreen: false,
      silkAccelerated: false,
      colorDepth: -1,
      pixelDepth: -1,
      resolution: [],
      cpuCores: -1,
      language: "unknown",
      browser: "unknown",
      version: "unknown",
      os: "unknown",
      platform: "unknown",
      geoIp: {},
      source: "",
      hashInt: function (string) {
        var hash = 0,
            i,
            chr,
            len;

        if (string.length === 0) {
          return hash;
        }

        for (i = 0, len = string.length; i < len; i++) {
          chr = string.charCodeAt(i);
          hash = (hash << 5) - hash + chr;
          hash |= 0;
        }

        return hash;
      },
      hashMD5: function (string) {
        function rotateLeft(lValue, iShiftBits) {
          return lValue << iShiftBits | lValue >>> 32 - iShiftBits;
        }

        function addUnsigned(lX, lY) {
          var lX4, lY4, lX8, lY8, lResult;
          lX8 = lX & 2147483648;
          lY8 = lY & 2147483648;
          lX4 = lX & 1073741824;
          lY4 = lY & 1073741824;
          lResult = (lX & 1073741823) + (lY & 1073741823);

          if (lX4 & lY4) {
            return lResult ^ 2147483648 ^ lX8 ^ lY8;
          }

          if (lX4 | lY4) {
            if (lResult & 1073741824) {
              return lResult ^ 3221225472 ^ lX8 ^ lY8;
            } else {
              return lResult ^ 1073741824 ^ lX8 ^ lY8;
            }
          } else {
            return lResult ^ lX8 ^ lY8;
          }
        }

        function gF(x, y, z) {
          return x & y | ~x & z;
        }

        function gG(x, y, z) {
          return x & z | y & ~z;
        }

        function gH(x, y, z) {
          return x ^ y ^ z;
        }

        function gI(x, y, z) {
          return y ^ (x | ~z);
        }

        function gFF(a, b, c, d, x, s, ac) {
          a = addUnsigned(a, addUnsigned(addUnsigned(gF(b, c, d), x), ac));
          return addUnsigned(rotateLeft(a, s), b);
        }

        function gGG(a, b, c, d, x, s, ac) {
          a = addUnsigned(a, addUnsigned(addUnsigned(gG(b, c, d), x), ac));
          return addUnsigned(rotateLeft(a, s), b);
        }

        function gHH(a, b, c, d, x, s, ac) {
          a = addUnsigned(a, addUnsigned(addUnsigned(gH(b, c, d), x), ac));
          return addUnsigned(rotateLeft(a, s), b);
        }

        function gII(a, b, c, d, x, s, ac) {
          a = addUnsigned(a, addUnsigned(addUnsigned(gI(b, c, d), x), ac));
          return addUnsigned(rotateLeft(a, s), b);
        }

        function convertToWordArray(string) {
          var lWordCount;
          var lMessageLength = string.length;
          var lNumberOfWordsTemp1 = lMessageLength + 8;
          var lNumberOfWordsTemp2 = (lNumberOfWordsTemp1 - lNumberOfWordsTemp1 % 64) / 64;
          var lNumberOfWords = (lNumberOfWordsTemp2 + 1) * 16;
          var lWordArray = new Array(lNumberOfWords - 1);
          var lBytePosition = 0;
          var lByteCount = 0;

          while (lByteCount < lMessageLength) {
            lWordCount = (lByteCount - lByteCount % 4) / 4;
            lBytePosition = lByteCount % 4 * 8;
            lWordArray[lWordCount] = lWordArray[lWordCount] | string.charCodeAt(lByteCount) << lBytePosition;
            lByteCount++;
          }

          lWordCount = (lByteCount - lByteCount % 4) / 4;
          lBytePosition = lByteCount % 4 * 8;
          lWordArray[lWordCount] = lWordArray[lWordCount] | 128 << lBytePosition;
          lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
          lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
          return lWordArray;
        }

        function wordToHex(lValue) {
          var wordToHexValue = "",
              wordToHexValueTemp = "",
              lByte,
              lCount;

          for (lCount = 0; lCount <= 3; lCount++) {
            lByte = lValue >>> lCount * 8 & 255;
            wordToHexValueTemp = "0" + lByte.toString(16);
            wordToHexValue = wordToHexValue + wordToHexValueTemp.substr(wordToHexValueTemp.length - 2, 2);
          }

          return wordToHexValue;
        }

        function utf8Encode(string) {
          string = string.replace(/\r\n/g, "\n");
          var utftext = "";

          for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);

            if (c < 128) {
              utftext += String.fromCharCode(c);
            } else if (c > 127 && c < 2048) {
              utftext += String.fromCharCode(c >> 6 | 192);
              utftext += String.fromCharCode(c & 63 | 128);
            } else {
              utftext += String.fromCharCode(c >> 12 | 224);
              utftext += String.fromCharCode(c >> 6 & 63 | 128);
              utftext += String.fromCharCode(c & 63 | 128);
            }
          }

          return utftext;
        }

        var x = [];
        var k, AA, BB, CC, DD, a, b, c, d;
        var S11 = 7,
            S12 = 12,
            S13 = 17,
            S14 = 22;
        var S21 = 5,
            S22 = 9,
            S23 = 14,
            S24 = 20;
        var S31 = 4,
            S32 = 11,
            S33 = 16,
            S34 = 23;
        var S41 = 6,
            S42 = 10,
            S43 = 15,
            S44 = 21;
        string = utf8Encode(string);
        x = convertToWordArray(string);
        a = 1732584193;
        b = 4023233417;
        c = 2562383102;
        d = 271733878;

        for (k = 0; k < x.length; k += 16) {
          AA = a;
          BB = b;
          CC = c;
          DD = d;
          a = gFF(a, b, c, d, x[k + 0], S11, 3614090360);
          d = gFF(d, a, b, c, x[k + 1], S12, 3905402710);
          c = gFF(c, d, a, b, x[k + 2], S13, 606105819);
          b = gFF(b, c, d, a, x[k + 3], S14, 3250441966);
          a = gFF(a, b, c, d, x[k + 4], S11, 4118548399);
          d = gFF(d, a, b, c, x[k + 5], S12, 1200080426);
          c = gFF(c, d, a, b, x[k + 6], S13, 2821735955);
          b = gFF(b, c, d, a, x[k + 7], S14, 4249261313);
          a = gFF(a, b, c, d, x[k + 8], S11, 1770035416);
          d = gFF(d, a, b, c, x[k + 9], S12, 2336552879);
          c = gFF(c, d, a, b, x[k + 10], S13, 4294925233);
          b = gFF(b, c, d, a, x[k + 11], S14, 2304563134);
          a = gFF(a, b, c, d, x[k + 12], S11, 1804603682);
          d = gFF(d, a, b, c, x[k + 13], S12, 4254626195);
          c = gFF(c, d, a, b, x[k + 14], S13, 2792965006);
          b = gFF(b, c, d, a, x[k + 15], S14, 1236535329);
          a = gGG(a, b, c, d, x[k + 1], S21, 4129170786);
          d = gGG(d, a, b, c, x[k + 6], S22, 3225465664);
          c = gGG(c, d, a, b, x[k + 11], S23, 643717713);
          b = gGG(b, c, d, a, x[k + 0], S24, 3921069994);
          a = gGG(a, b, c, d, x[k + 5], S21, 3593408605);
          d = gGG(d, a, b, c, x[k + 10], S22, 38016083);
          c = gGG(c, d, a, b, x[k + 15], S23, 3634488961);
          b = gGG(b, c, d, a, x[k + 4], S24, 3889429448);
          a = gGG(a, b, c, d, x[k + 9], S21, 568446438);
          d = gGG(d, a, b, c, x[k + 14], S22, 3275163606);
          c = gGG(c, d, a, b, x[k + 3], S23, 4107603335);
          b = gGG(b, c, d, a, x[k + 8], S24, 1163531501);
          a = gGG(a, b, c, d, x[k + 13], S21, 2850285829);
          d = gGG(d, a, b, c, x[k + 2], S22, 4243563512);
          c = gGG(c, d, a, b, x[k + 7], S23, 1735328473);
          b = gGG(b, c, d, a, x[k + 12], S24, 2368359562);
          a = gHH(a, b, c, d, x[k + 5], S31, 4294588738);
          d = gHH(d, a, b, c, x[k + 8], S32, 2272392833);
          c = gHH(c, d, a, b, x[k + 11], S33, 1839030562);
          b = gHH(b, c, d, a, x[k + 14], S34, 4259657740);
          a = gHH(a, b, c, d, x[k + 1], S31, 2763975236);
          d = gHH(d, a, b, c, x[k + 4], S32, 1272893353);
          c = gHH(c, d, a, b, x[k + 7], S33, 4139469664);
          b = gHH(b, c, d, a, x[k + 10], S34, 3200236656);
          a = gHH(a, b, c, d, x[k + 13], S31, 681279174);
          d = gHH(d, a, b, c, x[k + 0], S32, 3936430074);
          c = gHH(c, d, a, b, x[k + 3], S33, 3572445317);
          b = gHH(b, c, d, a, x[k + 6], S34, 76029189);
          a = gHH(a, b, c, d, x[k + 9], S31, 3654602809);
          d = gHH(d, a, b, c, x[k + 12], S32, 3873151461);
          c = gHH(c, d, a, b, x[k + 15], S33, 530742520);
          b = gHH(b, c, d, a, x[k + 2], S34, 3299628645);
          a = gII(a, b, c, d, x[k + 0], S41, 4096336452);
          d = gII(d, a, b, c, x[k + 7], S42, 1126891415);
          c = gII(c, d, a, b, x[k + 14], S43, 2878612391);
          b = gII(b, c, d, a, x[k + 5], S44, 4237533241);
          a = gII(a, b, c, d, x[k + 12], S41, 1700485571);
          d = gII(d, a, b, c, x[k + 3], S42, 2399980690);
          c = gII(c, d, a, b, x[k + 10], S43, 4293915773);
          b = gII(b, c, d, a, x[k + 1], S44, 2240044497);
          a = gII(a, b, c, d, x[k + 8], S41, 1873313359);
          d = gII(d, a, b, c, x[k + 15], S42, 4264355552);
          c = gII(c, d, a, b, x[k + 6], S43, 2734768916);
          b = gII(b, c, d, a, x[k + 13], S44, 1309151649);
          a = gII(a, b, c, d, x[k + 4], S41, 4149444226);
          d = gII(d, a, b, c, x[k + 11], S42, 3174756917);
          c = gII(c, d, a, b, x[k + 2], S43, 718787259);
          b = gII(b, c, d, a, x[k + 9], S44, 3951481745);
          a = addUnsigned(a, AA);
          b = addUnsigned(b, BB);
          c = addUnsigned(c, CC);
          d = addUnsigned(d, DD);
        }

        var temp = wordToHex(a) + wordToHex(b) + wordToHex(c) + wordToHex(d);
        return temp.toLowerCase();
      }
    };
    (this || _global).Agent = {};

    (this || _global).getBrowser = function (string) {
      switch (true) {
        case (this || _global)._Browsers.Edge.test(string):
          (this || _global).Agent.isEdge = true;
          return "Edge";

        case (this || _global)._Browsers.PhantomJS.test(string):
          (this || _global).Agent.isPhantomJS = true;
          return "PhantomJS";

        case (this || _global)._Browsers.Konqueror.test(string):
          (this || _global).Agent.isKonqueror = true;
          return "Konqueror";

        case (this || _global)._Browsers.Amaya.test(string):
          (this || _global).Agent.isAmaya = true;
          return "Amaya";

        case (this || _global)._Browsers.Epiphany.test(string):
          (this || _global).Agent.isEpiphany = true;
          return "Epiphany";

        case (this || _global)._Browsers.SeaMonkey.test(string):
          (this || _global).Agent.isSeaMonkey = true;
          return "SeaMonkey";

        case (this || _global)._Browsers.Flock.test(string):
          (this || _global).Agent.isFlock = true;
          return "Flock";

        case (this || _global)._Browsers.OmniWeb.test(string):
          (this || _global).Agent.isOmniWeb = true;
          return "OmniWeb";

        case (this || _global)._Browsers.Opera.test(string):
          (this || _global).Agent.isOpera = true;
          return "Opera";

        case (this || _global)._Browsers.Chromium.test(string):
          (this || _global).Agent.isChrome = true;
          return "Chromium";

        case (this || _global)._Browsers.Chrome.test(string):
          (this || _global).Agent.isChrome = true;
          return "Chrome";

        case (this || _global)._Browsers.Safari.test(string):
          (this || _global).Agent.isSafari = true;
          return "Safari";

        case (this || _global)._Browsers.WinJs.test(string):
          (this || _global).Agent.isWinJs = true;
          return "WinJs";

        case (this || _global)._Browsers.IE.test(string):
          (this || _global).Agent.isIE = true;
          return "IE";

        case (this || _global)._Browsers.PS3.test(string):
          return "ps3";

        case (this || _global)._Browsers.PSP.test(string):
          return "psp";

        case (this || _global)._Browsers.Firefox.test(string):
          (this || _global).Agent.isFirefox = true;
          return "Firefox";

        case (this || _global)._Browsers.UC.test(string):
          (this || _global).Agent.isUC = true;
          return "UCBrowser";

        default:
          // If the UA does not start with Mozilla guess the user agent.
          if (string.indexOf("Mozilla") !== 0 && /^([\d\w\-\.]+)\/[\d\w\.\-]+/i.test(string)) {
            (this || _global).Agent.isAuthoritative = false;
            return RegExp.$1;
          }

          return "unknown";
      }
    };

    (this || _global).getBrowserVersion = function (string) {
      var regex;

      switch ((this || _global).Agent.browser) {
        case "Edge":
          if ((this || _global)._Versions.Edge.test(string)) {
            return RegExp.$1;
          }

          break;

        case "PhantomJS":
          if ((this || _global)._Versions.PhantomJS.test(string)) {
            return RegExp.$1;
          }

          break;

        case "Chrome":
          if ((this || _global)._Versions.Chrome.test(string)) {
            return RegExp.$1;
          }

          break;

        case "Chromium":
          if ((this || _global)._Versions.Chromium.test(string)) {
            return RegExp.$1;
          }

          break;

        case "Safari":
          if ((this || _global)._Versions.Safari.test(string)) {
            return RegExp.$1;
          }

          break;

        case "Opera":
          if ((this || _global)._Versions.Opera.test(string)) {
            return RegExp.$1 ? RegExp.$1 : RegExp.$2;
          }

          break;

        case "Firefox":
          if ((this || _global)._Versions.Firefox.test(string)) {
            return RegExp.$1;
          }

          break;

        case "WinJs":
          if ((this || _global)._Versions.WinJs.test(string)) {
            return RegExp.$1;
          }

          break;

        case "IE":
          if ((this || _global)._Versions.IE.test(string)) {
            return RegExp.$2 ? RegExp.$2 : RegExp.$1;
          }

          break;

        case "ps3":
          if ((this || _global)._Versions.Ps3.test(string)) {
            return RegExp.$1;
          }

          break;

        case "psp":
          if ((this || _global)._Versions.Psp.test(string)) {
            return RegExp.$1;
          }

          break;

        case "Amaya":
          if ((this || _global)._Versions.Amaya.test(string)) {
            return RegExp.$1;
          }

          break;

        case "Epiphany":
          if ((this || _global)._Versions.Epiphany.test(string)) {
            return RegExp.$1;
          }

          break;

        case "SeaMonkey":
          if ((this || _global)._Versions.SeaMonkey.test(string)) {
            return RegExp.$1;
          }

          break;

        case "Flock":
          if ((this || _global)._Versions.Flock.test(string)) {
            return RegExp.$1;
          }

          break;

        case "OmniWeb":
          if ((this || _global)._Versions.OmniWeb.test(string)) {
            return RegExp.$1;
          }

          break;

        case "UCBrowser":
          if ((this || _global)._Versions.UC.test(string)) {
            return RegExp.$1;
          }

          break;

        default:
          if ((this || _global).Agent.browser !== "unknown") {
            regex = new RegExp((this || _global).Agent.browser + "[\\/ ]([\\d\\w\\.\\-]+)", "i");

            if (regex.test(string)) {
              return RegExp.$1;
            }
          }

      }
    };

    (this || _global).getOS = function (string) {
      switch (true) {
        case (this || _global)._OS.WindowsVista.test(string):
          (this || _global).Agent.isWindows = true;
          return "Windows Vista";

        case (this || _global)._OS.Windows7.test(string):
          (this || _global).Agent.isWindows = true;
          return "Windows 7";

        case (this || _global)._OS.Windows8.test(string):
          (this || _global).Agent.isWindows = true;
          return "Windows 8";

        case (this || _global)._OS.Windows81.test(string):
          (this || _global).Agent.isWindows = true;
          return "Windows 8.1";

        case (this || _global)._OS.Windows10.test(string):
          (this || _global).Agent.isWindows = true;
          return "Windows 10.0";

        case (this || _global)._OS.Windows2003.test(string):
          (this || _global).Agent.isWindows = true;
          return "Windows 2003";

        case (this || _global)._OS.WindowsXP.test(string):
          (this || _global).Agent.isWindows = true;
          return "Windows XP";

        case (this || _global)._OS.Windows2000.test(string):
          (this || _global).Agent.isWindows = true;
          return "Windows 2000";

        case (this || _global)._OS.WindowsPhone8.test(string):
          return "Windows Phone 8";

        case (this || _global)._OS.Linux64.test(string):
          (this || _global).Agent.isLinux = true;
          (this || _global).Agent.isLinux64 = true;
          return "Linux 64";

        case (this || _global)._OS.Linux.test(string):
          (this || _global).Agent.isLinux = true;
          return "Linux";

        case (this || _global)._OS.ChromeOS.test(string):
          (this || _global).Agent.isChromeOS = true;
          return "Chrome OS";

        case (this || _global)._OS.Wii.test(string):
          return "Wii";

        case (this || _global)._OS.PS3.test(string):
          return "Playstation";

        case (this || _global)._OS.PSP.test(string):
          return "Playstation";

        case (this || _global)._OS.OSXCheetah.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Cheetah";

        case (this || _global)._OS.OSXPuma.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Puma";

        case (this || _global)._OS.OSXJaguar.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Jaguar";

        case (this || _global)._OS.OSXPanther.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Panther";

        case (this || _global)._OS.OSXTiger.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Tiger";

        case (this || _global)._OS.OSXLeopard.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Leopard";

        case (this || _global)._OS.OSXSnowLeopard.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Snow Leopard";

        case (this || _global)._OS.OSXLion.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Lion";

        case (this || _global)._OS.OSXMountainLion.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Mountain Lion";

        case (this || _global)._OS.OSXMavericks.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Mavericks";

        case (this || _global)._OS.OSXYosemite.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X Yosemite";

        case (this || _global)._OS.OSXElCapitan.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X El Capitan";

        case (this || _global)._OS.OSXSierra.test(string):
          (this || _global).Agent.isMac = true;
          return "macOS Sierra";

        case (this || _global)._OS.Mac.test(string):
          (this || _global).Agent.isMac = true;
          return "OS X";

        case (this || _global)._OS.iPad.test(string):
          (this || _global).Agent.isiPad = true;
          return string.match((this || _global)._OS.iPad)[0].replace("_", ".");

        case (this || _global)._OS.iPhone.test(string):
          (this || _global).Agent.isiPhone = true;
          return string.match((this || _global)._OS.iPhone)[0].replace("_", ".");

        case (this || _global)._OS.Bada.test(string):
          (this || _global).Agent.isBada = true;
          return "Bada";

        case (this || _global)._OS.Curl.test(string):
          (this || _global).Agent.isCurl = true;
          return "Curl";

        default:
          return "unknown";
      }
    };

    (this || _global).getPlatform = function (string) {
      switch (true) {
        case (this || _global)._Platform.Windows.test(string):
          return "Microsoft Windows";

        case (this || _global)._Platform.WindowsPhone.test(string):
          (this || _global).Agent.isWindowsPhone = true;
          return "Microsoft Windows Phone";

        case (this || _global)._Platform.Mac.test(string):
          return "Apple Mac";

        case (this || _global)._Platform.Curl.test(string):
          return "Curl";

        case (this || _global)._Platform.Android.test(string):
          (this || _global).Agent.isAndroid = true;
          return "Android";

        case (this || _global)._Platform.Blackberry.test(string):
          (this || _global).Agent.isBlackberry = true;
          return "Blackberry";

        case (this || _global)._Platform.Linux.test(string):
          return "Linux";

        case (this || _global)._Platform.Wii.test(string):
          return "Wii";

        case (this || _global)._Platform.Playstation.test(string):
          return "Playstation";

        case (this || _global)._Platform.iPad.test(string):
          (this || _global).Agent.isiPad = true;
          return "iPad";

        case (this || _global)._Platform.iPod.test(string):
          (this || _global).Agent.isiPod = true;
          return "iPod";

        case (this || _global)._Platform.iPhone.test(string):
          (this || _global).Agent.isiPhone = true;
          return "iPhone";

        case (this || _global)._Platform.Samsung.test(string):
          (this || _global).Agent.isiSamsung = true;
          return "Samsung";

        default:
          return "unknown";
      }
    };

    (this || _global).testCompatibilityMode = function () {
      var ua = this || _global;

      if ((this || _global).Agent.isIE) {
        if (/Trident\/(\d)\.0/i.test(ua.Agent.source)) {
          var tridentVersion = parseInt(RegExp.$1, 10);
          var version = parseInt(ua.Agent.version, 10);

          if (version === 7 && tridentVersion === 7) {
            ua.Agent.isIECompatibilityMode = true;
            ua.Agent.version = 11;
          }

          if (version === 7 && tridentVersion === 6) {
            ua.Agent.isIECompatibilityMode = true;
            ua.Agent.version = 10;
          }

          if (version === 7 && tridentVersion === 5) {
            ua.Agent.isIECompatibilityMode = true;
            ua.Agent.version = 9;
          }

          if (version === 7 && tridentVersion === 4) {
            ua.Agent.isIECompatibilityMode = true;
            ua.Agent.version = 8;
          }
        }
      }
    };

    (this || _global).testSilk = function () {
      var ua = this || _global;

      switch (true) {
        case new RegExp("silk", "gi").test(ua.Agent.source):
          (this || _global).Agent.isSilk = true;
          break;

        default:
      }

      if (/Silk-Accelerated=true/gi.test(ua.Agent.source)) {
        (this || _global).Agent.SilkAccelerated = true;
      }

      return (this || _global).Agent.isSilk ? "Silk" : false;
    };

    (this || _global).testKindleFire = function () {
      var ua = this || _global;

      switch (true) {
        case /KFOT/gi.test(ua.Agent.source):
          (this || _global).Agent.isKindleFire = true;
          return "Kindle Fire";

        case /KFTT/gi.test(ua.Agent.source):
          (this || _global).Agent.isKindleFire = true;
          return "Kindle Fire HD";

        case /KFJWI/gi.test(ua.Agent.source):
          (this || _global).Agent.isKindleFire = true;
          return "Kindle Fire HD 8.9";

        case /KFJWA/gi.test(ua.Agent.source):
          (this || _global).Agent.isKindleFire = true;
          return "Kindle Fire HD 8.9 4G";

        case /KFSOWI/gi.test(ua.Agent.source):
          (this || _global).Agent.isKindleFire = true;
          return "Kindle Fire HD 7";

        case /KFTHWI/gi.test(ua.Agent.source):
          (this || _global).Agent.isKindleFire = true;
          return "Kindle Fire HDX 7";

        case /KFTHWA/gi.test(ua.Agent.source):
          (this || _global).Agent.isKindleFire = true;
          return "Kindle Fire HDX 7 4G";

        case /KFAPWI/gi.test(ua.Agent.source):
          (this || _global).Agent.isKindleFire = true;
          return "Kindle Fire HDX 8.9";

        case /KFAPWA/gi.test(ua.Agent.source):
          (this || _global).Agent.isKindleFire = true;
          return "Kindle Fire HDX 8.9 4G";

        default:
          return false;
      }
    };

    (this || _global).testCaptiveNetwork = function () {
      var ua = this || _global;

      switch (true) {
        case /CaptiveNetwork/gi.test(ua.Agent.source):
          ua.Agent.isCaptive = true;
          ua.Agent.isMac = true;
          ua.Agent.platform = "Apple Mac";
          return "CaptiveNetwork";

        default:
          return false;
      }
    };

    (this || _global).testMobile = function testMobile() {
      var ua = this || _global;

      switch (true) {
        case ua.Agent.isWindows:
        case ua.Agent.isLinux:
        case ua.Agent.isMac:
        case ua.Agent.isChromeOS:
          ua.Agent.isDesktop = true;
          break;

        case ua.Agent.isAndroid:
        case ua.Agent.isSamsung:
          ua.Agent.isMobile = true;
          ua.Agent.isDesktop = false;
          break;

        default:
      }

      switch (true) {
        case ua.Agent.isiPad:
        case ua.Agent.isiPod:
        case ua.Agent.isiPhone:
        case ua.Agent.isBada:
        case ua.Agent.isBlackberry:
        case ua.Agent.isAndroid:
        case ua.Agent.isWindowsPhone:
          ua.Agent.isMobile = true;
          ua.Agent.isDesktop = false;
          break;

        default:
      }

      if (/mobile/i.test(ua.Agent.source)) {
        ua.Agent.isMobile = true;
        ua.Agent.isDesktop = false;
      }
    };

    (this || _global).testTablet = function testTablet() {
      var ua = this || _global;

      switch (true) {
        case ua.Agent.isiPad:
        case ua.Agent.isAndroidTablet:
        case ua.Agent.isKindleFire:
          ua.Agent.isTablet = true;
          break;
      }

      if (/tablet/i.test(ua.Agent.source)) {
        ua.Agent.isTablet = true;
      }
    };

    (this || _global).testNginxGeoIP = function testNginxGeoIP(headers) {
      var ua = this || _global;
      Object.keys(headers).forEach(function (key) {
        if (/^GEOIP/i.test(key)) {
          ua.Agent.geoIp[key] = headers[key];
        }
      });
    };

    (this || _global).testBot = function testBot() {
      var ua = this || _global;
      var isBot = IS_BOT_REGEXP.exec(ua.Agent.source.toLowerCase());

      if (isBot) {
        ua.Agent.isBot = isBot[1];
      } else if (!ua.Agent.isAuthoritative) {
        // Test unauthoritative parse for `bot` in UA to flag for bot
        ua.Agent.isBot = /bot/i.test(ua.Agent.source);
      }
    };

    (this || _global).testSmartTV = function testBot() {
      var ua = this || _global;
      var isSmartTV = new RegExp("smart-tv|smarttv|googletv|appletv|hbbtv|pov_tv|netcast.tv", "gi").exec(ua.Agent.source.toLowerCase());

      if (isSmartTV) {
        ua.Agent.isSmartTV = isSmartTV[1];
      }
    };

    (this || _global).testAndroidTablet = function testAndroidTablet() {
      var ua = this || _global;

      if (ua.Agent.isAndroid && !/mobile/i.test(ua.Agent.source)) {
        ua.Agent.isAndroidTablet = true;
      }
    };

    (this || _global).testTouchSupport = function () {
      var ua = this || _global;
      ua.Agent.isTouchScreen = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    };

    (this || _global).getLaguage = function () {
      var ua = this || _global;
      ua.Agent.language = (navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage || "").toLowerCase();
    };

    (this || _global).getColorDepth = function () {
      var ua = this || _global;
      ua.Agent.colorDepth = screen.colorDepth || -1;
    };

    (this || _global).getScreenResolution = function () {
      var ua = this || _global;
      ua.Agent.resolution = [screen.availWidth, screen.availHeight];
    };

    (this || _global).getPixelDepth = function () {
      var ua = this || _global;
      ua.Agent.pixelDepth = screen.pixelDepth || -1;
    };

    (this || _global).getCPU = function () {
      var ua = this || _global;
      ua.Agent.cpuCores = navigator.hardwareConcurrency || -1;
    };

    (this || _global).reset = function reset() {
      var ua = this || _global;

      for (var key in ua.DefaultAgent) {
        if (ua.DefaultAgent.hasOwnProperty(key)) {
          ua.Agent[key] = ua.DefaultAgent[key];
        }
      }

      return ua;
    };

    (this || _global).parse = function get(source) {
      source = source || navigator.userAgent;
      var ua = new DeviceUUID();
      ua.Agent.source = source.replace(/^\s*/, "").replace(/\s*$/, "");
      ua.Agent.os = ua.getOS(ua.Agent.source);
      ua.Agent.platform = ua.getPlatform(ua.Agent.source);
      ua.Agent.browser = ua.getBrowser(ua.Agent.source);
      ua.Agent.version = ua.getBrowserVersion(ua.Agent.source);
      ua.testBot();
      ua.testSmartTV();
      ua.testMobile();
      ua.testAndroidTablet();
      ua.testTablet();
      ua.testCompatibilityMode();
      ua.testSilk();
      ua.testKindleFire();
      ua.testCaptiveNetwork();
      ua.testTouchSupport();
      ua.getLaguage();
      ua.getColorDepth();
      ua.getPixelDepth();
      ua.getScreenResolution();
      ua.getCPU();
      return ua.Agent;
    };

    (this || _global).get = function (customData) {
      var pref = "a",
          du = this.parse();
      var dua = [];

      for (var key in (this || _global).options) {
        if ((this || _global).options.hasOwnProperty(key) && (this || _global).options[key] === true) {
          dua.push(du[key]);
        }
      }

      if (customData) {
        dua.push(customData);
      }

      if (!(this || _global).options.resolution && du.isMobile) {
        dua.push(du.resolution);
      } // 8, 9, a, b


      pref = "b";
      var tmpUuid = du.hashMD5(dua.join(":"));
      var uuid = [tmpUuid.slice(0, 8), tmpUuid.slice(8, 12), "4" + tmpUuid.slice(12, 15), pref + tmpUuid.slice(15, 18), tmpUuid.slice(20)];
      return uuid.join("-");
    };

    (this || _global).Agent = (this || _global).DefaultAgent;
    return this || _global;
  };

  exports.DeviceUUID = DeviceUUID;
  return new DeviceUUID(navigator.userAgent);
})(exports);

export default exports;